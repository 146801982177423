import React from "react"
import PopUpProvider from "./src/context/PopUpContext"
import UserProvider from "./src/context/UserContext"
import FlowProvider from "./src/context/FlowContext"
import TrackingProvider from "./src/context/TrackingContext"
import { ApolloProvider } from "@apollo/react-hooks"
import { client } from "./src/apollo/client"

export const wrapRootElement = ({ element }) => (
  <ApolloProvider client={client}>
    <UserProvider>
      <TrackingProvider>
        <FlowProvider>
          <PopUpProvider>{element}</PopUpProvider>
        </FlowProvider>
      </TrackingProvider>
    </UserProvider>
  </ApolloProvider>
)
