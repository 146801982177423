import ApolloClient from "apollo-boost"
import fetch from "isomorphic-fetch"

// const aUrlPath = window.location.pathname.split("/")
// const token = aUrlPath[aUrlPath.length - 1]
// const bPreview = aUrlPath[1].toLowerCase() === "preview" || false


export const client = new ApolloClient({
  fetch,
  uri: process.env.GATSBY_GRAPHQL_ENDPOINT,
  // headers: {
  //   Authorization: bPreview ? `Bearer ${token}` : "",
  // },
})
