import React, { useEffect } from "react"
import { storeUniqueId } from "helpers/storeUniqueId"


import Cookies from "js-cookie"
import { useMutation } from "@apollo/react-hooks"
import gql from "graphql-tag"

const SEND_EVENT_TRACKER = gql`
  mutation (
    $event_name: String!
    $value: String!
    $userID: String!
  ) {
    createTrackEvent (
      input: {
        clientMutationId: "Event Tracker"
        event_name: $event_name
        value: $value
        userID: $userID
      }
    ) {
      data
      success
    }
  }
`

export const TrackingContext = React.createContext()

const TrackingContextProvider = ({ children }) => {
  const [createTrackMySafetyEvent] = useMutation(SEND_EVENT_TRACKER, {
    ignoreResults: true,
  })

  const trackEvent = (event_name, value) => {
    const uniqueId = Cookies.get("uom-ff-id")

    createTrackMySafetyEvent({
      variables: {
        event_name: event_name,
        value: value,
        userID: uniqueId,
      },
    })
  }

  useEffect(() => {
    console.log("Tracking Context")

  
    storeUniqueId("uom-ff-id", 4)
  
  }, [])

  const contextValues = {
    trackEvent
  }

  return (
    <TrackingContext.Provider value={contextValues}>
      {children}
    </TrackingContext.Provider>
  )
}

export default TrackingContextProvider
