import uniqid from "uniqid"
import Cookies from "js-cookie"

export const storeUniqueId = (label, expires) => {
  const cookieExists = Cookies.get(label)

  if (cookieExists) {
    return false
  } else {
    const uniqueId = uniqid()
    Cookies.set(label, uniqueId, { expires: expires })
  }
}
