import React, { useState, useEffect, useContext } from "react"
import { useStaticQuery, graphql, navigate } from "gatsby"
import useMedia from "hooks/useMedia.js"
import { scrollTo } from "helpers/scrollTo"
import { UserContext } from "./UserContext"
import { TrackingContext } from "./TrackingContext"
import { getClosest } from "helpers/getClosest"
import { useMutation } from "@apollo/react-hooks"
import gql from "graphql-tag"
import usePrevious from "hooks/usePrevious.js"

export const FlowContext = React.createContext()

const CREATE_QUESTIONAIRE_RESULT = gql`
  mutation CreateQuestionaireResult($answerData: String!) {
    createQuestionaireResult(
      input: { clientMutationId: "Results Submission", answerData: $answerData }
    ) {
      data
      success
    }
  }
`

const FlowProvider = ({ children }) => {
  const wpData = useStaticQuery(graphql`
    {
      allWpQuestion(sort: { fields: menuOrder, order: ASC }) {
        nodes {
          title
          menuOrder
          databaseId
          details {
            description
            type
            step
          }
          questionAnswers {
            title
            databaseID
            type
            imageUrl
            description
            skipTo
            skipToUrl
            skipToQuestion
          }
          branchSettings {
            branchQuestion {
              ... on WpBranch {
                title
                databaseId
                branchGroup {
                  name {
                    ... on WpBranchGroup {
                      title
                      databaseId
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `)

  const [createQuestionaireResult, { data }] = useMutation(
    CREATE_QUESTIONAIRE_RESULT
  )

  const screensData = wpData.allWpQuestion.nodes
  const [flowDisabled, setFlowDisabled] = useState(false)
  const [flowFinished, setFlowFinished] = useState(false)

  const [currentScreen, setCurrentScreen] = useState(screensData[0])
  const currentScreenIndex = screensData.indexOf(currentScreen)
  const firstScreen = currentScreenIndex === 0
  const lastScreen = currentScreenIndex === screensData.length - 1
  const { responses } = useContext(UserContext)
  const { trackEvent } = useContext(TrackingContext)


  const isMobile = useMedia(
    // Media queries
    ["(max-width: 767px)"],
    //options
    [true],
    // default
    false
  )

  const previousScreenIndex = usePrevious(currentScreenIndex) || -1
  const forwards = currentScreenIndex > previousScreenIndex
  const goToScreen = id => {
    const specificScreen = screensData.find(screen => screen.databaseId === id)
    setCurrentScreen(specificScreen)
  }

  const handleBranchExit = direction => {
    const branchQuestions =
      currentScreen.branchSettings.branchQuestion &&
      screensData.filter(
        question =>
          question.branchSettings.branchQuestion &&
          question.branchSettings.branchQuestion.title ===
            currentScreen.branchSettings.branchQuestion.title
      )
    let potentialQuestions = null

    const first = branchQuestions && branchQuestions[0]
    const last = branchQuestions && branchQuestions[branchQuestions.length - 1]

    if (
      direction === "back" ||
      (direction === "prev" && currentScreen === first) ||
      (direction === "next" && currentScreen === last)
    ) {
      if (
        direction === "back" ||
        (direction === "prev" && currentScreen === first)
      ) {
        console.log("first")
        potentialQuestions = screensData.slice(0, currentScreenIndex)
      }

      if (direction === "next" && currentScreen === last) {
        // console.log("last")
        potentialQuestions = screensData.slice(
          currentScreenIndex,
          screensData.length
        )
      }

      // go through potential and find first question with no branchSettings.branchQuestion

      const nonBranchQuestions = potentialQuestions.filter(
        question => !question.branchSettings.branchQuestion
      )

      const nonBranchPositions = nonBranchQuestions.map(
        question => question.menuOrder
      )

      const currentPosition = currentScreen.menuOrder

      const closestNonBranchPosition = getClosest(
        currentPosition,
        nonBranchPositions
      )

      const closestNonBranchQuestion = nonBranchQuestions.find(
        question => question.menuOrder === closestNonBranchPosition
      )

      setCurrentScreen(closestNonBranchQuestion)
      return true
    } else {
      return false
    }
  }

  const handlePrev = () => {
    if (currentScreen.branchSettings.branchQuestion) {
      handleBranchExit("prev")
        ? handleBranchExit("prev")
        : setCurrentScreen(screensData[currentScreenIndex - 1])
    } else {
      handleBranchExit("back")
    }
  }

  const handleNext = answer => {
    if (lastScreen) {
      return false
    }
    if (answer.skipToQuestion) {
      goToScreen(answer.skipToQuestion)
      return false
    } else if (currentScreen.branchSettings.branchQuestion) {
      handleBranchExit("next")
        ? handleBranchExit("next")
        : setCurrentScreen(screensData[currentScreenIndex + 1])
    } else {
      setCurrentScreen(screensData[currentScreenIndex + 1])
    }
  }

  useEffect(() => {
    if (data) {
      const resultHashid = JSON.parse(data.createQuestionaireResult.data)
        .resultHashid
      navigate(`/results/${resultHashid}`)
    }
  }, [data])

  useEffect(() => {
    const lastResult = responses.find(
      response =>
        response.questionId === screensData[screensData.length - 1].databaseId
    )

    if (lastResult) {

      setFlowFinished(true);
      trackEvent("end","end");
      createQuestionaireResult({
        variables: {
          answerData: JSON.stringify(responses),
        },
      })
    }
  }, [responses])

  useEffect(() => {
    if (isMobile) {
      scrollTo("top-of-page", "auto")
    }

    trackEvent("screen", currentScreen.databaseId);
  }, [currentScreen])

  const contextValues = {
    currentScreen,
    setCurrentScreen,
    currentScreenIndex,
    handlePrev,
    handleNext,
    firstScreen,
    forwards,
    screensData,
    lastScreen,
    isMobile,
    flowFinished,
    setFlowFinished
  }

  return (
    <FlowContext.Provider value={contextValues}>
      {children}
    </FlowContext.Provider>
  )
}

export default FlowProvider
