import React, { useState, useEffect } from "react"

export const UserContext = React.createContext()

const UserProvider = ({ children }) => {
  const [responses, setResponses] = useState([])

  const storeResponse = (
    questionId,
    questionLabel,
    answerId,
    answerLabel,
    answerValue
  ) => {
    let updatedResponses = responses.filter(
      response => response.questionId !== questionId
    )
    setResponses([
      ...updatedResponses,
      {
        questionId: questionId,
        questionLabel: questionLabel,
        answerId: answerId,
        answerLabel: answerLabel,
        answerValue: answerValue || "",
      },
    ])
  }

  const contextValues = {
    responses,
    storeResponse,
    setResponses,
  }

  useEffect(() => {}, [responses])

  return (
    <UserContext.Provider value={contextValues}>
      {children}
    </UserContext.Provider>
  )
}

export default UserProvider
